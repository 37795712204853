import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef(null);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        setShowForm(false);
      }
      if (navbarRef.current && !navbarRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    if (showForm || isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showForm, isOpen]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    setSubmitted(false); // Reset submitted state when form opens
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    // Form data
    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      date: formData.get('date'),
      time: formData.get('time'),
      message: formData.get('message'),
    };

    try {
      const response = await fetch('https://api-service.webartstudios.in/nishasdentalcare/appointmentform.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitted(true);
        // Reset form fields or close form
        e.target.reset();
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/" className='brand-name'><img src='/logo.png' alt='logo' />Nisha's Dental Care </Link>
        </div>
        <div className={isOpen ? "navbar-links-mobile" : "navbar-links"}>
          <Link to="/" onClick={closeNavbar}>Home</Link>
          <Link to="/about" onClick={closeNavbar}>About</Link>
          <Link to="/services" onClick={closeNavbar}>Services</Link>
          <Link to="/courses" onClick={closeNavbar}>Courses</Link>
          <Link to="/contact" onClick={closeNavbar}>Contact</Link>
          <Link to="/certifications" onClick={closeNavbar}>Achievements & Certifications</Link>
          <Link to="/blogs" onClick={closeNavbar}>Know More</Link>
          <button onClick={toggleForm} className='button-45'>Take an Appointment</button>
        </div>
        <div className="navbar-view-menu">
          <button onClick={toggleForm} className='button-41'>Take an Appointment</button>
        </div>
        <div className="navbar-toggle">
          <button onClick={toggleNavbar}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {showForm && (
        <div className="popup-overlay">
          <div className="popup-form" ref={formRef}>
            <h2>Appointment Form</h2>
            {submitted ? (
              <p className="form-success">Successfully sent!</p>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input type="text" id="name" name="name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone:</label>
                  <input type="tel" id="phone" name="phone" required />
                </div>
                <div className="form-group">
                  <label htmlFor="date">Date:</label>
                  <input type="date" id="date" name="date" required />
                </div>
                <div className="form-group">
                  <label htmlFor="time">Time:</label>
                  <select name="time" id="time">
                    <option value="morning">Morning</option>
                    <option value="afternoon">Afternoon</option>
                    <option value="evening">Evening</option>
                  
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message (Optional):</label>
                  <textarea id="message" name="message" rows="4"></textarea>
                </div>
                <button type="submit" className="submit-button" disabled={submitting}>
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>
                <button type="button" className="close-form-button" onClick={toggleForm}>Close</button>
              </form>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
