import React, { useState, useRef, useEffect } from 'react';
import './HeroSection.css';
const HeroSection = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef(null);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
    setSubmitted(false); // Reset the form submission state
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setIsFormOpen(false);
    }
  };

  useEffect(() => {
    if (isFormOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFormOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    // Form data
    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      date: formData.get('date'),
      time: formData.get('time'),
      message: formData.get('message'),
    };

    try {
      const response = await fetch('https://api-service.webartstudios.in/nishasdentalcare/appointmentform.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitted(true);
        // Reset form fields or close form
        e.target.reset();
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className="hero-section">
      <h1>Nisha's Dental Care</h1>
      <div className="buttons">
        <a href="#services" className="button-41">Our Services</a>
        <button onClick={toggleForm} className="button-42">Take an Appointment</button>
      </div>
      {isFormOpen && (
        <div className="popup-overlay">
          <div className="popup-form" ref={formRef}>
            <h2>Appointment Form</h2>
            {submitted ? (
              <p className="form-success">Successfully sent!</p>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input type="text" id="name" name="name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone:</label>
                  <input type="tel" id="phone" name="phone" required />
                </div>
                <div className="form-group">
                  <label htmlFor="date">Date:</label>
                  <input type="date" id="date" name="date" required />
                </div>
                <div className="form-group">
                  <label htmlFor="time">Time:</label>
                  <select name="time" id="time">
                    <option value="morning">Morning</option>
                    <option value="afternoon">Afternoon</option>
                    <option value="evening">Evening</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message (Optional):</label>
                  <textarea id="message" name="message" rows="4"></textarea>
                </div>
                <button type="submit" className="submit-button" disabled={submitting}>
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>
                <button type="button" className="close-form-button" onClick={toggleForm}>Close</button>
              </form>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default HeroSection;
